import React from 'react';
import { InformacaoComplementarContent } from './InformacaoComplementarContent';
import { InformacaoComplementarContextProvider } from './InformacaoComplementarContext';

export const CadastroDeInformacaoComplementar: React.FC = () => {
  return (
    <InformacaoComplementarContextProvider>
      <InformacaoComplementarContent />
    </InformacaoComplementarContextProvider>
  );
};
