import React, { useContext, useState, useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { Container } from './styles';
import Search from '~/components/Search';
import { loadingContext } from '~/context/loading';
import FormDefault from '~/components/FormDefault';
import { useInformacaoComplementar } from './InformacaoComplementarContext';
import { InputText, InputText2, InputTextArea } from '~/components/NovosInputs';
import api from '~/services/api';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';

export const InformacaoComplementarContent: React.FC = () => {
  const {
    formInformacaoComplementar: {
      getValues,
      setValue,
      register,
      control,
      formState: { errors },
      handleSubmit,
      watch,
    },
    isUpdate,
    setIsUpdate,
    showSearch,
    setShowSearch,
    resetFormData,
    onDelete,
  } = useInformacaoComplementar();
  const { setLoading, loading } = useContext(loadingContext);
  const watchCodInfoComplementar = watch('cod_info_complementar');

  useEffect(() => {
    resetFormData();
  }, []);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { des_informacao, des_titulo, cod_info_complementar } = row;

    setValue('cod_info_complementar', cod_info_complementar);
    setValue('des_titulo', des_titulo);
    setValue('des_informacao', des_informacao);

    setIsUpdate(true);
    setLoading(false);
    setShowSearch(false);
  };

  const onNewData = () => {
    setShowSearch(false);
  };
  const onSave = handleSubmit(async (data) => {
    try {
      if (isUpdate) {
        const { data: responseData } = await api.put(
          `/informacao-complementar/${watchCodInfoComplementar}`,
          data,
        );

        if (responseData.success) {
          toast.success(responseData.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data: responseData } = await api.post(
        `/informacao-complementar`,
        data,
      );

      if (responseData.success) {
        toast.success(responseData.message);
        resetFormData();
      } else {
        toast.error(responseData.message);
      }
      resetFormData();
    } finally {
      setLoading(false);
    }
  });

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={292} newData={onNewData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={292}
          title="Cadastro de Informação Complementar"
          codigoRegistro={[
            {
              value: watchCodInfoComplementar,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          isUpdate={isUpdate}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={() => {
            resetFormData();
            setIsUpdate(false);
          }}
          onDelete={onDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setIsUpdate(false);
            setShowSearch(true);
            resetFormData();
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <InputText
                maxLength={50}
                label="Descrição"
                placeholder="Digite aqui a Descrição"
                name="des_titulo"
                caseInput="upper"
                toLowerCase={false}
                register={register}
                isError={!!errors.des_titulo}
              />
            </div>
            <div className="col-sm-12 col-md-12" style={{ marginTop: '10px' }}>
              <InputTextArea
                label="Detalhamento"
                register={register}
                control={control}
                disabled={false}
                name="des_informacao"
                placeholder="Informe o Detalhamento"
                style={{ resize: 'none' }}
                rows={5}
                maxLength={150}
                isError={!!errors.des_informacao}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};
