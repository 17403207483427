import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { schema } from './validations/FormDataValidation';
import api from '~/services/api';
import { toast } from 'react-toastify';

type FormType = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
};

type InformacaoComplementarContextData = {
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  formInformacaoComplementar: FormType;
  resetFormData: () => void;
  onDelete: () => void;
};

export const InformacaoComplementarContext = createContext(
  {} as InformacaoComplementarContextData,
);
interface InformacaoComplementarContextProviderProps {
  children: ReactNode;
}

export function InformacaoComplementarContextProvider({
  children,
}: InformacaoComplementarContextProviderProps): JSX.Element {
  const [isUpdate, setIsUpdate] = useState(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);

  /**
   * Form InformacaoComplementar
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  /**
   * RESET FORM DATA
   */
  const resetFormData = () => {
    reset({
      des_titulo: '',
      des_informacao: '',
    });
    setIsUpdate(false);
  };

  const onDelete = async () => {
    const { cod_info_complementar } = getValues();
    const { data } = await api.delete(
      `/informacao-complementar/${cod_info_complementar}`,
    );

    if (data.success) {
      resetFormData();
      setShowSearch(true);
      return toast.success(data.message);
    }
  };

  return (
    <InformacaoComplementarContext.Provider
      value={{
        isUpdate,
        setIsUpdate,
        showSearch,
        setShowSearch,
        resetFormData,
        onDelete,
        formInformacaoComplementar: {
          watch,
          reset,
          control,
          register,
          setValue,
          setError,
          getValues,
          setFocus,
          formState,
          clearErrors,
          handleSubmit,
        },
      }}
    >
      {children}
    </InformacaoComplementarContext.Provider>
  );
}

export const useInformacaoComplementar =
  (): InformacaoComplementarContextData => {
    return useContext(InformacaoComplementarContext);
  };
