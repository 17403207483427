import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import { Fildset } from '~/components/Fildset';
import FormDefault from '~/components/FormDefault';
import {
  InputRadio,
  InputSelect,
  InputText,
  InputTextArea,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';

import { Container } from './styles';
import { SelectProps } from './types';
import { schema } from './validations';

const CadastroDeInterface: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [flgInativo, setFlgInativo] = useState<boolean>(false);
  const [tipoGeracao, setTipoGeracao] = useState<number>(1);
  const [tipoInterface, setTipoInterface] = useState<SelectProps>({
    label: '',
    value: undefined,
  });

  const defaultOptions = {
    tipoInterface: [
      {
        label: 'Balança',
        value: 1,
      },
      {
        label: 'Busca Preço',
        value: 7,
      },
      {
        label: 'Coletor Dados',
        value: 2,
      },
      {
        label: 'Contabilidade',
        value: 6,
      },
      {
        label: 'Fiscal',
        value: 4,
      },
      {
        label: 'Financeira',
        value: 5,
      },
      {
        label: 'Força de Venda',
        value: 9,
      },
      {
        label: 'Movimento',
        value: 3,
      },
      {
        label: 'PDV',
        value: 0,
      },
      {
        label: 'Rede Cred.',
        value: 8,
      },
    ],
  };

  const formBlank = {
    descricao: '',
    apelido: '',
    tipoInterface: {
      label: '',
      value: undefined,
    },
    des_arquivo: '',
  };

  useEffect(() => {
    if (tipoInterface.value === undefined) {
      setValue('tipoInterface', {
        label: undefined,
        value: undefined,
      });
    } else {
      setValue('tipoInterface', {
        label: tipoInterface.label,
        value: tipoInterface.value,
      });
    }
  }, [setValue, tipoInterface]);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      setLoader(true);
      const { row } = param;
      const {
        cod_interface,
        des_apelido,
        des_arquivo,
        des_interface,
        flg_inativo,
        tipo_dado,
        tipo_interface,
        tipo_interface_view,
      } = row;

      reset();
      setTipoInterface({
        label: tipo_interface_view,
        value: tipo_interface,
      });
      setValue('descricao', des_interface);
      setValue('apelido', des_apelido);
      setValue('tipoInterface', {
        label: tipo_interface_view,
        value: tipo_interface,
      });
      setValue('des_arquivo', des_arquivo);

      setValue('cod_interface', cod_interface);
      setFlgInativo(flg_inativo);
      setTipoGeracao(Number(tipo_dado));
      setUpdate(true);
      setShowSearch(false);
      setLoader(false);
    },
    [setValue],
  );

  const resetFormData = () => {
    reset(formBlank);
    setFlgInativo(false);
    setTipoGeracao(Number(0));
    setTipoInterface({
      label: '',
      value: undefined,
    });
    setUpdate(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (isUpdate) {
      const res = await api.put(
        `/cadastro-de-interface/${data.cod_interface}`,
        {
          ...data,
        },
      );

      const { success, message } = res.data;

      if (!success) {
        return toast.warning(message);
      }

      if (success) {
        resetFormData();
        setShowSearch(true);
        return toast.success(message);
      }
    }
    const res = await api.post('/cadastro-de-interface', data);

    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }

    resetFormData();
    setShowSearch(false);
    return toast.success(message);
  });

  const handleDelete = async () => {
    const values = getValues();
    const { cod_interface } = values;
    const res = await api.delete(`/cadastro-de-interface/${cod_interface}`);
    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setShowSearch(false);
  };

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={195} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={195}
          title="Cadastro de Interface"
          onSave={onSubmit}
          codigoRegistro={[
            {
              value: getValues('cod_interface'),
              des_campo: 'Código',
            },
          ]}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                maxLength={500}
                placeholder="Informe a Descrição"
                name="descricao"
                toLowerCase={false}
                register={register}
                disabled={false}
                isError={!!errors.descricao}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <ToggleDefault
                labelText="Inativo?"
                setChecked={flgInativo}
                onSwitch={() => {
                  setValue('flgInativo', !flgInativo);
                  setFlgInativo(!flgInativo);
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <InputText
              label="Apelido"
              maxLength={500}
              placeholder="Informe o Apelido"
              name="apelido"
              toLowerCase={false}
              register={register}
              disabled={false}
              isError={!!errors.apelido}
            />
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <InputSelect
                label="Tipo de Interface"
                placeholder="Selecione..."
                maxLength={50}
                name="tipoInterface"
                register={register}
                isError={!!errors.tipoInterface}
                control={control}
                options={defaultOptions.tipoInterface}
                changeSelected={(selected: SelectProps) => {
                  setValue('tipoInterface', selected);
                  setTipoInterface(selected);
                }}
              />
            </div>
            <div className="ms-2 col-sm-auto mt-2 pt-0 ps-1 pb-0 pe-4">
              <Fildset label="Geração">
                <>
                  <div className="col-auto me-1">
                    <InputRadio
                      label="Texto"
                      name="tipoGeracao"
                      value={0}
                      register={register}
                      disabled={false}
                      checked={tipoGeracao === 0}
                      onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                        setTipoGeracao(Number(e.currentTarget.value))
                      }
                      isError={!!errors.tipoGeracao}
                    />
                  </div>
                  <div className="col-auto me-1">
                    <InputRadio
                      label="DBF"
                      name="tipoGeracao"
                      value={1}
                      register={register}
                      disabled={false}
                      checked={tipoGeracao === 1}
                      onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                        setTipoGeracao(Number(e.currentTarget.value))
                      }
                      isError={!!errors.tipoGeracao}
                    />
                  </div>
                  <div className="col-auto me-1">
                    <InputRadio
                      label="BD&nbsp;SQL"
                      name="tipoGeracao"
                      value={2}
                      register={register}
                      disabled={false}
                      checked={tipoGeracao === 2}
                      onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                        setTipoGeracao(Number(e.currentTarget.value))
                      }
                      isError={!!errors.tipoGeracao}
                    />
                  </div>
                </>
              </Fildset>
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <InputTextArea
              maxLength={500}
              label="Nome dos Arquivos"
              placeholder='Digite o nome dos arquivos separados por ";"'
              name="des_arquivo"
              register={register}
              isError={!!errors.des_arquivo}
              control={control}
            />
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroDeInterface;
